/******************************************************************************
*  @Filename: global.scss
*  @Date: 17-12-2021
*  @Author: Adrien Lanco
*
*  Description:
*******************************************************************************/

/******************************************************************************/
/*  Name: global.scss                                                         */
/*  Date: 28/10/2021                                                          */
/*  Author: Jbristhuille                                                      */
/*                                                                            */
/*  Description: Global style                                                 */
/******************************************************************************/

/* SUMMARY
  * Core CSS required for Ionic components to work properly
  * Optional CSS utils that can be commented out
  * Progress bar
  * Alert
  * Modal
*/

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* Progress bar */
ion-progress-bar {
  position: absolute;
  top: 0;
  z-index: 10;
}
/***/

/* Alert */
.system-alert .alert-wrapper {
  width: 100%;
  max-width: 600px;
  margin: 10px;
}
/***/

/* Modal */
.system-modal {
  --height: auto;

  .modal-wrapper {
    background: transparent !important;
    box-shadow: none !important;

    .ion-page {
      position: relative;
      contain: content;

      ion-toolbar {
        $background: none;
        background: none;
        --background: none;
        box-shadow: none !important;
      }

      ion-footer::before {
        background-image: none !important;
      }

      .modal-content {
        max-height: 30vh;
        overflow-y: scroll;
      }
      .modal-content::-webkit-scrollbar {
        width: 10px;
      }
      .modal-content::-webkit-scrollbar-track {
        background: #eee;
        border-radius: 4px;
      }
      .modal-content::-webkit-scrollbar-thumb {
        height: 10px;
        background-clip: content-box;
        border-radius: 4px;
        background: #78C72E;
      }

      .modal-content::-webkit-scrollbar-thumb:hover {
        border-radius: 4px;
        background: #78C72E;
      }
    }
  }
}
/***/

.gce-loader {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.gce-loader:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #78C72E transparent #78C72E transparent;
  animation: gce-loader 1.2s linear infinite;
}
@keyframes gce-loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

ion-button {
  p {
    margin-right: 5px;
  }
}

ion-item, ion-label {
  overflow: visible !important;
}

.tooltiped-item {
  z-index: 2;
}
.tooltiped-label {
  display: flex !important;
  align-items: center;
  justify-content: flex-start;
}

.gce-pin-error {
  cursor: help;
  z-index: 100;
  position: absolute;
  top: 25px;
  right: 25px;
  width: 25px;
  height: 25px;
  ion-icon {
    font-size: 25px !important;
    pointer-events: none;
  }
}

.gce-trait-error {
  cursor: help;
  z-index: 100;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    width: 32px;
    height: 32px;
    background: var(--ion-color-medium);
    border-radius: 50%;
    padding: 3px;
    border: solid 1px var(--ion-color-warning);

    ion-icon {
      font-size: 25px !important;
      pointer-events: none;
    }
  }
}

.smartgce-content {
  --background: var(--ion-content-background-color);
  background: var(--ion-content-background-color);
}


.gce-login-container {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 0;

  ion-card {
    width: 100%;
    margin: 0;
    top: 50%;
    padding: 20px;
    padding-top: 140px;
    padding-bottom: 150px;
    transform: translateY(-52%);
    background: #6a6a6a;
    background-image: linear-gradient(to right bottom, #262626, #6a6a6a);

    ion-title {
      padding: 0;
      text-align: left;
    }
    ion-list-header {
      flex-direction: column;
      align-items: flex-start;
    }
    ion-card-content, ion-list, ion-list-header, ion-item, ion-toolbar {
      z-index: 3;
      --ion-color-base: rgba(0,0,0,0) !important;
      background: rgba(0,0,0,0);
      background-color: rgba(0,0,0,0);
    }
    ion-toolbar {
      display: flex;
      div {
        display: flex;
        justify-content: space-between;
      }
      ion-buttons {
        justify-content: center;
      }
    }
    .gce-login-card {
      max-width: 600px;
      margin: auto;

      .img-container {
        width: 100%;
        display: flex;
        justify-content: center;
      }
    }
  }
}
.bg-bubbles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  z-index: 1;
}
.bg-bubbles li {
  position: absolute;
  list-style: none;
  display: block;
  width: 40px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.15);
  bottom: -160px;
  -webkit-animation: square 25s infinite;
  animation: square 25s infinite;
  transition-timing-function: linear;
}
.bg-bubbles li:nth-child(1) {
  left: 10%;
}
.bg-bubbles li:nth-child(2) {
  left: 20%;
  width: 80px;
  height: 80px;
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
  -webkit-animation-duration: 17s;
          animation-duration: 17s;
}
.bg-bubbles li:nth-child(3) {
  left: 25%;
  -webkit-animation-delay: 4s;
          animation-delay: 4s;
}
.bg-bubbles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  -webkit-animation-duration: 22s;
          animation-duration: 22s;
  background-color: rgba(255, 255, 255, 0.25);
}
.bg-bubbles li:nth-child(5) {
  left: 70%;
}
.bg-bubbles li:nth-child(6) {
  left: 80%;
  width: 120px;
  height: 120px;
  -webkit-animation-delay: 3s;
          animation-delay: 3s;
  background-color: rgba(255, 255, 255, 0.2);
}
.bg-bubbles li:nth-child(7) {
  left: 32%;
  width: 160px;
  height: 160px;
  -webkit-animation-delay: 7s;
          animation-delay: 7s;
}
.bg-bubbles li:nth-child(8) {
  left: 55%;
  width: 20px;
  height: 20px;
  -webkit-animation-delay: 15s;
          animation-delay: 15s;
  -webkit-animation-duration: 40s;
          animation-duration: 40s;
}
.bg-bubbles li:nth-child(9) {
  left: 25%;
  width: 10px;
  height: 10px;
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
  -webkit-animation-duration: 40s;
          animation-duration: 40s;
  background-color: rgba(255, 255, 255, 0.3);
}
.bg-bubbles li:nth-child(10) {
  left: 90%;
  width: 160px;
  height: 160px;
  -webkit-animation-delay: 11s;
          animation-delay: 11s;
}
@-webkit-keyframes square {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-700px) rotate(600deg);
            transform: translateY(-700px) rotate(600deg);
  }
}
@keyframes square {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-700px) rotate(600deg);
            transform: translateY(-700px) rotate(600deg);
  }
}
